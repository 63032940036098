import * as React from "react"
import { Link } from "gatsby"

function Whatsapp() {
    if (typeof document !== 'undefined') {
        var source = new URL(document.location.href).searchParams.get('utm_source');
        var mesg_text = false;
          
        switch(source) {
            case 'gads':
              mesg_text = "https://wa.me/6285218289639?text=Halo ReStat, Saya mau konsultasi";
            break;
            
            default:
            break;
        }
        
        var msg=mesg_text;
        }
        if(mesg_text) {msg=msg}
        else {msg='https://wa.me/6285218289639?text=Halo ReStat, Aku mau konsultasi'}

    return (
        <div className="go-top">
            <p>
                <Link to={msg} target='_blank'><i className="flaticon-whatsapp"></i></Link>

            </p>
        </div>
    );
}

  export default Whatsapp